import React from 'react';
import FooterSectionData from "../../data/FooterSection.json";
import LocalizationContext from "../../context/LocalizationContext";

const FooterSection = () => {
  const state = {
    data: FooterSectionData.FooterSection,
    footerList_2: FooterSectionData.FooterSection.footerList_2,
    footerList_3: FooterSectionData.FooterSection.footerList_3,
  };
  const { t } = React.useContext(LocalizationContext);
  const today = new Date();
    return (
      <section id="footer" >
      <div>
        <div className="height-emulator d-none d-lg-block" />
        <footer className="footer-area footer-fixed">
          {/* Footer Top */}
          <div className="ptb_100">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="navbar-brand" href="/#">
                      <img
                        className="logo"
                        src={state.data.image}
                        alt=""
                      />
                    </a>
                    <p className="mt-2 mb-3">{state.data.text}</p>
                    <p className="mt-2 mb-3">{state.data.text1}</p>
                    <p className="mt-2 mb-3">{state.data.text2}</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mt-2 mb-2">
                    {t("6:1")}
                    </h3>
                    <ul>
                      {state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href={item.link} target="_blank" rel="noopener noreferrer">{t(`6:${item.id}`)}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {/* Copyright Area */}
                  <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                    {/* Copyright Left */}
                    <div className="copyright-left">
                      © Copyrights {today.getFullYear()}. PiOTY All rights reserved.
                    </div>
                    {/* Copyright Right */}
                    <div className="copyright-right">
                      Made with <i className="fas fa-heart" /> By{" "}
                      <a href="/#">PiOTY</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      </section>
    );
}

export default FooterSection;