import React from "react";
import ServiceSectionData from "../../data/ServiceSection.json";
import LocalizationContext from "../../context/LocalizationContext";
const ServiceSection = () => {
  const state = {
    data: ServiceSectionData.ServiceSection,
    serviceData: ServiceSectionData.ServiceSection.serviceData,
  };
  const { t } = React.useContext(LocalizationContext);
  return (
    <>
      <section id="services" className="section service-area bg-gray overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-6 order-1 order-lg-1 justify-content-center">
              {/* Service Text */}
              <div className="service-text pt-4 pt-lg-0 text-center">
                <h2 className="text-capitalize mb-4">
                  {t("4:1")}
                </h2>
                {/* Service List */}
                <ul className="service-list">
                  {state.serviceData.map((item, idx) => {
                    return (
                      <div key={`so_${idx}`}>
                        {/* Single Service */}
                        <li className="single-service media py-2">
                          <div className="service-icon pr-4">
                            <span>
                              <i className={item.iconClass} />
                            </span>
                          </div>
                          <div className="service-text media-body">
                            <p style={{fontSize: 16}}>{t(`4:${item.id}`)}</p>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-4 order-2 order-lg-2 d-lg-block mt-4">
              {/* Service Thumb */}
              <div className="service-thumb mx-auto">
                <img src={state.data.thumbOne} alt="" />
              </div>

              <div className="plan-button text-center mt-5">
                <a href="mailto:info@pioty.com" className="btn btn-lg btn-block">
                  {t("2:4")}
                </a>
              </div>

            </div>
          </div>
        </div>
      </section>
      <div className="icon text-center mt-4">
        <a href="#discover" className="scroll">
          <img src={"/img/down.png"} style={{ width: 60, height: 60 }} alt="" />
        </a>
      </div>
    </>
  );
}

export default ServiceSection;
