import React from 'react';
import LocalizationContext from "../../context/LocalizationContext";

const Header = (props) => {

  const { t } = React.useContext(LocalizationContext);
  const { i18n } = React.useContext(LocalizationContext);
  return (
    <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
      <div className="container position-relative">
        <a className="navbar-brand" href="/">
          <img
            className="navbar-brand-regular"
            src={props.imageData}
            alt="brand-logo"
          />
          <img
            className="navbar-brand-sticky"
            src="/img/logo.png"
            alt="sticky brand-logo"
          />
        </a>
        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-toggle="navbarToggler"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="navbar-inner" data-toggle="navbarToggler">
          {/*  Mobile Menu Toggler */}
          <button
            className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* <nav>
            <ul className="navbar-nav" id="navbar-nav">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {t("2:6")}
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <li className="languages">
                    <div style={{fontSize: 12}} onClick={() => i18n.changeLanguage("en")} className="dropdown-item" >English → English</div>
                  </li>
                  <li className="languages">
                    <div style={{fontSize: 12}} onClick={() => i18n.changeLanguage("nl")} className="dropdown-item" >Dutch → Netherlands</div>
                  </li>
                  <li className="languages">
                    <div style={{fontSize: 12}} onClick={() => i18n.changeLanguage("am")} className="dropdown-item" >Amharic → አማርኛ</div>
                    </li>
                  <li className="languages">
                    <div style={{fontSize: 12}} onClick={() => i18n.changeLanguage("ar")} className="dropdown-item" >Arabic → عربى</div>
                  </li>
                  <li className="languages">
                    <div style={{fontSize: 12}} onClick={() => i18n.changeLanguage("fr")} className="dropdown-item" >French → Français</div>
                    </li>
                  <li className="languages">
                    <div style={{fontSize: 12}} onClick={() => i18n.changeLanguage("so")} className="dropdown-item" >Somali → Somali</div>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#features">
                  {t("2:1")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#services">
                  {t("2:5")}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link scroll" href="#discover">
                  {t("2:2")}
                </a>
              </li>
            </ul>
          </nav> */}
        </div>
      </div>
    </header>
  );
}

export default Header;