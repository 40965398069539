import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import am from "./context/lang/am";
import ar from "./context/lang/ar";
import en from "./context/lang/en";
import fr from "./context/lang/fr";
import nl from "./context/lang/nl";
import so from "./context/lang/so";

const resources = {
  "am": am, 
  "ar": ar,
  "en": en, 
  "fr": fr, 
  "nl": nl, 
  "so": so, 
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en'
  });

export default i18n;
