import React from 'react';
import LocalizationContext from "../../context/LocalizationContext";

const HeroSection = () => {
    const { t } = React.useContext(LocalizationContext);

    return (
        <>
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        {/* Welcome Intro Start */}
                        <div className="col-12 col-md-7 col-lg-8">
                            <div className="welcome-intro text-center">
                                <h1 className="text-black my-1" >{t("1:1")}</h1>
                                <div className="welcome-bottom">
                                    {/* Store Buttons */}
                                    <div className="button-group store-buttons d-flex justify-content-center">
                                        <a href="/#">
                                            <img src={"/img/welcome_mockup.png"} alt="" />
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Shape Bottom */}
                <div className="shape-bottom">
                    <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="fill" d="M0,6V0h1000v100L0,6z" />
                    </svg>
                </div>
            </section>
            <div className="icon text-center">
                <a href="#features" className="scroll">
                    <img src={"/img/down.png"} style={{ width: 60, height: 60 }} alt="" />
                </a>
            </div>
        </>
    );
}

export default HeroSection;