import React from 'react';
import FeatureSectionData from "../../data/FeatureSection.json";
import LocalizationContext from "../../context/LocalizationContext";
const FeatureSection = () => {
    const state = {
        data: FeatureSectionData.FeatureSection,
        featureData: FeatureSectionData.FeatureSection.featureData,
    }
    const { t } = React.useContext(LocalizationContext);
    return (
        <>
            <section id="features" className="section features-area style-two overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <span className="d-inline-block rounded-pill shadow-sm fw-5 px-4 py-2 mb-1">
                                    <i className="far fa-lightbulb text-primary mr-1" />
                                    <span style={{ fontSize: 25 }} >{t("2:1")}</span> 
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {state.featureData.map((item, idx) => {
                            return (
                                <div key={`fo_${idx}`} className="col-10 col-md-10 col-lg-10 res-margin mb-3">
                                    {/* Image Box */}
                                    <div className="image-box text-center icon-1 p-5">
                                        {/* Featured Image */}
                                        <div className="featured-img mb-3">
                                            <img className="avatar-sm" src={item.image} alt="" />
                                        </div>
                                        {/* Icon Text */}
                                        <div className="icon-text">
                                            <p style={{fontSize: 16}}>{t(`3:${item.id}`)}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            <div className="icon text-center mt-4">
                <a href="#services" className="scroll">
                    <img src={"/img/down.png"} style={{ width: 60, height: 60 }} alt="" />
                </a>
            </div>
        </>
    );
}

export default FeatureSection;