import React, { Component } from 'react';

import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroSix';
import FeatureSection from '../components/Features/FeatureSix';
import ServiceSection from '../components/ServiceSection/ServiceSix';
import DiscoverSection from '../components/DiscoverSection/DiscoverSix';
import Download from '../components/DownloadSection/Download';
import FooterSection from '../components/FooterSection/Footer';

class ThemeSix extends Component {
    render() {
        return (
            <div className="homepage-6">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <HeroSection />
                    <FeatureSection />
                    <ServiceSection />
                    <DiscoverSection />
                    <Download />
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ThemeSix;