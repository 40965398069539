import React from "react";
import DiscoverSectionData from "../../data/DiscoverSection.json";
import LocalizationContext from "../../context/LocalizationContext";
const DiscoverSection = () => {
  const state = {
    data: DiscoverSectionData.DiscoverSection,
    discoverData: DiscoverSectionData.DiscoverSection.discoverData,
    discoverIcon: DiscoverSectionData.DiscoverSection.discoverIcon,
  };
  const { t } = React.useContext(LocalizationContext);
    return (
      <section id="discover" className="section discover-area overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
              {/* Discover Thumb */}
              <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0">
              <img src={state.data.thumbOne} width={500} height={500} alt="" />
              </div>
              <div className="plan-button text-center mt-4 mb-3">
                    <a href="mailto:info@pioty.com" className="btn btn-lg btn-block">
                         {t("2:4")}
                    </a>
                </div>
            </div>
            <div className="col-12 col-lg-6 order-1 order-lg-2">
              {/* Discover Text */}
              <div className="discover-text pt-4 pt-lg-0">
                <h2 className="pb-4 pb-sm-0">{t("5:1")}</h2>
                <p className="d-none d-sm-block pt-3 pb-4">
                </p>
                {/* Check List */}
                <ul className="check-list">
                  {state.discoverData.map((item, idx) => {
                    return (
                      <div key={`do_${idx}`}>
                        <li className="py-2">
                          {/* List Box */}
                          <div className="list-box media">
                            <span className="icon align-self-center">
                              <i className={item.iconClass} />  
                            </span>
                            <p style={{fontSize: 16}}>&nbsp;{t(`5:${item.id}`)}</p>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
                <div className="icon-box d-flex mt-3">
                  {state.discoverIcon.map((item, idx) => {
                    return (
                      <div key={`il_${idx}`} className="service-icon pr-3">
                        <span>
                          <i className={item.iconClass} />
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

export default DiscoverSection;
