import React from 'react';

const Download = () => {
    return (
        <section id="download" className="section download-area overlay-dark ptb_50">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                            <img
                                className="nav-item"
                                src={"/img/logo-white.png"}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Download;